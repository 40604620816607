import React from 'react';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Responsibilities from './components/Responsibilities';
import Projects from './components/Projects';
import { siteInfo } from './misc/information';

function App() {

    return (
      <div className="App">
        <div className="home-container">
          <Header alt={siteInfo.header.alt} />

        </div>
        <Nav />
        <About
          title={siteInfo.about.title}
          alt={siteInfo.about.alt}
          text={siteInfo.about.text} />
        <Responsibilities
          title={siteInfo.Responsibilities.title}
          alt={siteInfo.Responsibilities.alt}
          text={siteInfo.Responsibilities.text} />
        <Projects
          title={siteInfo.Projects.title}
          alt={siteInfo.Projects.alt}
          text={siteInfo.Projects.text} />
      </div>
    );
}

export default App;
