export const siteInfo = {
	header: {
    	alt: "anacapa logo"
	},
	heroAlt: "anacapa",
	about: {
    	title: "Summary",
    	text: [
        	"I worked at Anacapa Micro Products for 5 years as an Account Manager specializing in technical sales for enterprise IT solutions.",
        	"My primary focus was working with the Department of Defense, intelligence agencies, and prime contractors like Northrop Grumman.",
        	"Over the course of three years, I significantly expanded our presence at Northrop Grumman, growing the account from a single contact to multiple programs across all divisions."
    	]
	},
	Responsibilities: {
    	title: "Responsibilities",
    	text: [
        	"Conducted technical sales, holding certifications from Cisco, HP, and Polycom.",
        	"Worked with customers such as Northrop Grumman, the National Geospatial-Intelligence Agency, the Navy, and JPL.",
        	"Traveled to customer facilities to scope projects and collaborate with program leadership and procurement teams.",
        	"Identified new programs and opportunities while specifying hardware and software solutions to meet project requirements."
    	]
	},
	Projects: {
    	title: "Projects",
    	text: [
        	"I contributed to a wide range of projects, including:",
                "Outfitting server rooms.",
        	"Supplying equipment for Sensitive Compartmented Information Facilities (SCIFs)",
        	"Deploying radio repeater systems.",
        	"Overhauling and optimizing network infrastructures.",
        	"Sourcing board-level components for critical applications."
    	]
	}
}

